@charset "UTF-8";

@import "variables";
@import "normalize";

@font-face {
	font-family: 'Bariol';
	src: url('../fonts/bariol_regular.ttf') format('truetype');
	src: url('../fonts/bariol_regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/bariol_regular.svg#bariol_regularregular') format('svg'),
	url('../fonts/bariol_regular.woff') format('woff');
	font-weight: normal;
	font-style: normal
}

/*
@font-face {
	font-family: 'Bariol';
	src: url('../fonts/bariol_bold.ttf') format('truetype');
	src: url('../fonts/bariol_bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/bariol_bold.woff') format('woff');
	font-weight: bold;
	font-style: normal
}
*/

@font-face {
	font-family: 'BariolB';
	src: url('../fonts/bariol_bold.ttf') format('truetype');
	src: url('../fonts/bariol_bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/bariol_bold.woff') format('woff');
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: 'Bariol';
	src: url('../fonts/bariol_regular_italic.ttf') format('truetype');
	src: url('../fonts/bariol_regular_italic.eot?#iefix') format('embedded-opentype'),
	url('../fonts/bariol_regular_italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	@font-face {
		font-family: 'Bariol';
		src: url('../fonts/bariol_regular.svg#bariol_regularregular') format('svg');
		font-weight: normal;
		font-style: normal
	}

	/*
	@font-face {
		font-family: 'Bariol';
		src: url('../fonts/bariol_bold.svg#Bariol') format('svg');
		font-weight: bold;
		font-style: normal
	}
	*/

	@font-face {
		font-family: 'Bariol';
		src: url('../fonts/bariol_regular_italic.svg#bariolregular_italic') format('svg');
		font-weight: normal;
		font-style: italic;
	}
}

html {
	font-size: 10px;
}

html, button, input, select, textarea {
	font-family: $font-text;
}

button, input, select, textarea {
	@include rem(font-size, $font-size);
}

body, li, td, th {
	@include rem(font-size, $font-size);
	line-height: normal;
	font-family: $font-text;
}

a {
	color: $color-text;
	text-decoration: none;

	&:hover {
		color: $color-base;
	}
}

nav {
	ul {
		display: block;
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

td, th {
	vertical-align: top;
	padding: 5px;
}

h1,
h2,
h3,
h4,
h5 {
	line-height: 1.2;
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 32px;
}

h3 {
	font-size: 26px;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 18px;
}

ul, ol {
	margin: 15px 0;
	padding: 0 0 0 15px;
}

p {
	&:first-child {
		margin-top: 0;
	}
}

.alignLeft,
.alignRight {
	display: block;
}

.alignLeft {
	float: left;
	margin: 0 30px 30px 0;
}

.alignRight {
	float: right;
	margin: 0 0 30px 30px;
}

.textContent {
	@include rem(font-size, $font-size);
	line-height: $line-height;

	ul {
		padding-left: 0;
		list-style: none;

		li {
			display: block;
			padding-left: 8px;
			position: relative;
			@include rem(font-size, $font-size);
			line-height: $line-height;

			&:before {
				content: '\2022';
				display: block;
				position: absolute;
				left: 1px;
				top: 0;
				@include rem(font-size, .8rem);
				line-height: 3;
			}
		}
	}

	a {
		&:hover {
			color: $color-highlight;
		}
	}
}

.columnedText {
	.column {
		width: 48%;
		float: right;

		&:first-child {
			float: left;
		}
	}
}

.button {
	display: inline-block;
	@include rem(font-size, 1.4rem);
	line-height: 1.2;
	color: #fff;
	background: $color-base;
	padding: 10px 15px;
	text-transform: uppercase;
	@include transition();

	.fa {
		vertical-align: 5%;
		margin-right: 5px;
	}

	&:hover {
		color: #fff;
		background: darken($color-base, 15%);
	}

	&.alt {
		background: $color-dark;

		&:hover {
			background: lighten($color-dark, 15%);
		}
	}
}

button.button {
	border: none;
	overflow: visible;
}

.stripeTitle {
	font-size: 16px;
	color: #000;
	position: relative;
	overflow: hidden;

	&:after {
		content: ' ';
		display: inline-block;
		position: absolute;
		width: $width-axis;
		height: 2px;
		background: #e8e8e8;
		margin: 9px 25px;
	}

	a {
		color: #000;

		&:hover {
			color: $color-base;
		}
	}
}

.imageInfo {
	position: relative;

	.img {
		width: 48%;
		float: left;

		img {
			max-width: 100%;
			height: auto;
		}
	}

	.info {
		overflow: hidden;
		@include rem(font-size, 1.2rem);
	}

	.infoLabel {
		position: absolute;
		right: -30px;
		bottom: -30px;
	}
}

.aboutInfo {
	max-width: 900px;
	border-bottom: 1px solid #cccccc;

	.img {
		width: 56%;
		margin: 0 5% 0 -5%;
	}

	.info {
		margin-top: 90px;
		padding-bottom: 110px;
	}
}

.numberOneInfo  {
	.img {
		float: right;
		width: 50%;
		margin: 0 -2.5% 0 7%;
	}
}

.subTitledItem {
	text-align: justify;
	margin-bottom: 20px;

	.name {
		margin: 0;
		font-size: 24px;
		font-weight: bold;
		color: $color-base;
	}

	.subName {
		font-size: 16px;
		line-height: 1;
		margin: 0;
		color: $color-dark;
	}
}

.inRow {
	@include inRow(20px);
}

.specialTitle {
	margin: 30px 0;
	text-transform: uppercase;
	font-size: 18px;
	text-align: right;
	color: $color-dark;
	border-bottom: 1px solid #cccccc;
	padding-bottom: 7px;
}

.libraryLink {
	width: 100%;
	@include prefix(box-sizing, border-box);
	clear: both;
	overflow: hidden;
	text-align: right;
	padding-right: 10%;

	a {
		display: inline-block;
		position: relative;

		&:before {
			content: ' ';
			display: block;
			position: absolute;
			right: 100%;
			bottom: 0;
			margin: 0 30px 0 0;
			width: 1000px;
			height: 4px;
			background: $color-base;
		}
	}
}

.simpleTitle {
	font-size: 14px;
	margin: 0 0 10px;
	text-transform: uppercase;
	color: $color-dark;
}

.sectionName {
	padding-left: 20px;
	background: url(../img/small_logo.png) no-repeat left center;
	font-weight: normal;
	@include rem(font-size, 1.2rem);
	text-transform: lowercase;
	margin: 0 0 10px;
}

.simpleTable {
	border-collapse: separate;
	border-spacing: 0;

	td, th {
		border: 1px solid #fff;
		text-align: center;
		background: #eeeeef;
		padding: 2px;
		@include rem(font-size, 1rem);

		&:first-child {
			border-left: 0;
		}

		&:last-child {
			border-right: 0;
		}
	}

	.transparentHead {
		td, th {
			background: transparent !important;
			border-color: transparent !important;
		}
	}

	.sectionName {
		margin-bottom: 0;
		text-align: left;
	}
}
